export const COLORS = {
  purple: '#416AFF',
  purple2: '#e3e5fb',
  green: '#33CF71',
  orange: '#FF8D07',

  primary: '#6202ea',
  // primary: 'black',
  primaryDark: '#711bec', // deprecated
  primaryLight1: '#AF99E7',
  primaryLight2: '#BFADEC',
  primaryLight3: '#F1EEFB',

  white: '#FFFFFF',
  grey: '#eaeaea',
  greyLight: '#cfcfcf',
  greyLight2: '#f4f4f4',
  greyLight3: '#f5f5f5',
  greyLight4: '#e7e7e7',
  greyDark: '#717171',
  gray1: '#f4f4f4',
  gray2: '#e7e7e7',
  gray3: '#9a9a9a',
  backgroundGrey: '#f9f9f9',
  backgroundGreyLight: '#f9f9fa',
  grayModal: '#f8f8f8',

  black: '#000000',
  blackPrimary: '#313546',
  blackLight1: '#343434',
  blackLight2: '#272727',
  blackDark1: '#0F0F0F',
  blackDark2: '#030303',

  textPrimary: '#14012D',
  // purpleLight: '#E3E5FB',

  red: '#CF3333',
  red2: '#F3CCCC',
  red3: '#dc4f4f',

  green2: '#1CC54C',
  transparent: '#00000000',
  headerBg: '#212121',
  drifflePlus: '#9c60ea',
  drifflePlusText: '#9F62EB',
  topupColorText: '#AD3CF9',
};

// TO-DO
export const DarkTheme = {};

// TO-DO
export const LightTheme = {};

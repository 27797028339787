import styled from 'styled-components';
import React from 'react';
import styles from '../../styles/Icons.module.css';
import { COLORS } from '../../constants/colors';

export const RightArrowIcon = () => <span className={styles.rightArrowIcon} />;

export const LeftArrowIcon = () => <span className={styles.leftArrowIcon} />;

export const RightArrowBlackIcon = () => (
  <span className={styles.rightArrowBlackIcon} />
);

export const LeftArrowBlackIcon = () => (
  <span className={styles.leftArrowBlackIcon} />
);

export const SteamIconNew = () => <span className={styles.steamIconNew} />;

export const TrendingIcon = () => <span className={styles.trendingIcon} />;

export const StarIconPurple = () => <span className={styles.starIcon} />;

export const TopPreOrdersIcon = () => <span className={styles.rocketIcon} />;

export const PcIcon = () => <span className={styles.pcIcon} />;

export const PlaystationIcon = () => (
  <span className={styles.playstationIcon} />
);

export const XboxIcon = () => <span className={styles.xboxIcon} />;

export const NintendoIcon = () => <span className={styles.nintendoIcon} />;

export const SearchIcon = () => <span className={styles.searchIcon} />;

export const FpsIcon = () => <span className={styles.fpsIcon} />;

export const CrossIcon = () => <span className={styles.crossIcon} />;

export const CartIcon = () => <span className={styles.cartIcon} />;

export const HomeWhiteSmallIcon = () => <span className={styles.homeIcon} />;

export const CustomerSupportIconWhite = () => (
  <span className={styles.customerSupportIcon} />
);

export const HamburgerIconBlack = () => (
  <span className={styles.hamburgerIcon} />
);

export const SortIconBlack = () => <span className={styles.sortBlackIcon} />;

export const SortIconPurple = () => <span className={styles.sortPurpleIcon} />;

export const ArrowDownIconBlack = () => (
  <span className={styles.downArrowIcon} />
);

export const UpArrowFullIconBlack = () => (
  <span className={styles.upArrowFullBlackIcon} />
);

export const DownArrowFullIconBlack = () => (
  <span className={styles.downArrowFullBlackIcon} />
);

export const PlusIconPurple = () => <span className={styles.plusPurpleIcon} />;

export const CartSmallPurple = () => (
  <span className={styles.cartSmallPurpleIcon} />
);

export const CartMobileIcon = () => <span className={styles.cartMobileIcon} />;

export const CartDesktopIcon = () => (
  <span className={styles.cartDesktopIcon} />
);

export const StarSmallPurple = () => (
  <span className={styles.starSmallPurpleIcon} />
);

export const HeartPurpleIcon = () => (
  <span className={styles.heartPurpleIcon} />
);

export const HeartWhiteIcon = () => <span className={styles.heartWhiteIcon} />;

export const InformationPurpleIcon = () => (
  <span className={styles.informationPurpleIcon} />
);

export const InformationWhiteIcon = () => (
  <span className={styles.informationWhiteIcon} />
);

export const HeartEmptyIcon = () => <span className={styles.heartEmptyIcon} />;

export const SharePurpleIcon = () => (
  <span className={styles.sharePurpleIcon} />
);

export const MinusPurpleIcon = () => (
  <span className={styles.minusPurpleIcon} />
);

export const DeletePurpleIcon = () => (
  <span className={styles.deletePurpleIcon} />
);

export const UserPurpleIcon = () => <span className={styles.userPurpleIcon} />;

export const FacebookIcon = () => <span className={styles.facebookIcon} />;

export const GoogleIcon = () => <span className={styles.googleIcon} />;

export const SteamPurpleIcon = () => (
  <span className={styles.steamPurpleIcon} />
);

export const DiscordIcon = () => <span className={styles.discordIcon} />;

export const DiscordIconNew = () => <span className={styles.discordIconNew} />;

export const MailIcon = () => <span className={styles.mailIcon} />;

export const FilterPurpleIcon = () => (
  <span className={styles.filterPurpleIcon} />
);

export const FacebookPurpleIcon = () => (
  <span className={styles.facebookPurpleIcon} />
);

export const InstagramPurpleIcon = () => (
  <span className={styles.instagramPurpleIcon} />
);

export const YoutubePurpleIcon = () => (
  <span className={styles.youtubePurpleIcon} />
);

export const TwitterPurpleIcon = () => (
  <span className={styles.twitterPurpleIcon} />
);

export const UserBlackIcon = () => <span className={styles.userBlackIcon} />;

export const LogoutPurpleIcon = () => (
  <span className={styles.logoutPurpleIcon} />
);

export const SettingsPurpleIcon = () => (
  <span className={styles.settingsPurpleIcon} />
);

export const CartWhiteLargeIcon = () => (
  <span className={styles.cartWhiteLargeIcon} />
);

export const ContactusPrupleLargeIcon = () => (
  <span className={styles.contactusPurpleLargeIcon} />
);

export const CrossBlackSmallIcon = () => (
  <span className={styles.crossBlackSmallIcon} />
);

export const SuccessfulCheckmark = () => (
  <span className={styles.successCheckmarkIcon} />
);

interface MaskIconProps {
  url?: string;
  height?: string;
  width?: string;
  type?: 'black' | 'white';
  color?: string;
  margin?: string;
  selected?: boolean;
  default?:string;
}

/**
 * DON'T CHANGE
 */
export const MaskIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '25px'};
  width: ${(props) => props.width || '25px'};
  vertical-align: middle;

  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  mask-image: url(${(props) => props.url || ''});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background: ${(props) =>
    props.selected === true
      ? props.color || COLORS.blackDark1
      : props.default ? props.default:COLORS.greyLight};
`;
/**
 * DON'T CHANGE  ^
 */
interface BackgroundIconProps {
  height?: string;
  width?: string;
  margin?: string;
  backgroundImage?: string;
}

/**
 * DON'T CHANGE
 */
export const BackgroundIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '25px'};
  width: ${(props) => props.width || '25px'};
  vertical-align: middle;

  margin: ${(props) => props.margin || '0 0 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(${(props) => props.url || ''});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
/**
 * DON'T CHANGE  ^
 */

export const CartCouponsTrustIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;

  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  mask-image: url(/icons/coupons-icon.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: ${(props) =>
    props.selected === true
      ? props.color || COLORS.blackDark1
      : COLORS.greyLight};
`;

export const CartMoneybackTrustIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;

  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  mask-image: url(/icons/moneyback-icon.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: ${(props) =>
    props.selected === true
      ? props.color || COLORS.blackDark1
      : COLORS.greyLight};
`;

export const RightArrowMaskIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '35px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;

  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  mask-image: url(/icons/right-arrow-black.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: ${(props) =>
    props.selected === true
      ? props.color || COLORS.blackDark1
      : COLORS.greyLight};
`;

export const LeftArrowMaskIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '35px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;

  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  mask-image: url(/icons/left-arrow-black.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: ${(props) =>
    props.color ? props.color : props.type || 'white'};
`;

export const OtherPlatformBackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  // vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/platform_icons/other.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const CardPaymentBackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/payments-icons/card.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const NetBankingPaymentBackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/payments-icons/net-banking.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const UpiPaymentBackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/payments-icons/upi.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const WalletPaymentBackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/payments-icons/wallet.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const Pegi3BackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/pegi-icons/PEGI_3.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const Pegi7BackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/pegi-icons/PEGI_7.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const Pegi12BackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/pegi-icons/PEGI_12.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const Pegi16BackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/pegi-icons/PEGI_16.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const Pegi18BackgroundIcon = styled.span<BackgroundIconProps>`
  height: ${(props) => props.height || '20px'};
  width: ${(props) => props.width || '20px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/pegi-icons/PEGI_18.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const UPIColoredIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '32px'};
  width: ${(props) => props.width || '32px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/upi-colored.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const RupayColoredIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '32px'};
  width: ${(props) => props.width || '32px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/rupay.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const MastercardLogoColoredIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '32px'};
  width: ${(props) => props.width || '32px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/mastercard-logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const VisaColoredIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '32px'};
  width: ${(props) => props.width || '32px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/visa-colored.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;

export const CardPaymentIcon = styled.span<MaskIconProps>`
  height: ${(props) => props.height || '32px'};
  width: ${(props) => props.width || '32px'};
  vertical-align: middle;
  margin: ${(props) => props.margin || '0 4px 0 0'};
  position: relative;
  display: inline-block;
  background-image: url(/icons/card-payment.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
`;
